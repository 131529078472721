<template>
    <v-card
            class="user-card"
            color="accent darken-1"
            max-width="100%"
            min-width="45%"
            dark
    >
        <v-card-title class="pb-2">
            <v-avatar
                    class="avatar-right">
              <initials-avatar-component
                  :imgSrc="getImageUrl(message)"
                  :size="42"
                  :fullname="message.author"
              ></initials-avatar-component>
            </v-avatar>
            <span class="title">Du</span>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <span  v-on="on" class="subtitle-1 white--text">{{$moment(message.createdAt).fromNow()}}</span>
                </template>
                <span>{{$moment(message.createdAt).format('LLL')}}</span>
            </v-tooltip>
            <message-status-component class="ml-2" v-if="message.deliveryStatus"
                                      :status="message.deliveryStatus"></message-status-component>
        </v-card-title>
        <v-card-subtitle class="pt-1" v-if="message.emailAddress">
            <b>Till:</b>
            <div>
                <v-chip @click="emailAdressClick(message.emailAddress)"
                        color="white"
                        label outlined small>{{message.emailAddress}}
                </v-chip>
            </div>
        </v-card-subtitle>
        <v-card-text>
            <h5 class="ph-sensitive">{{message.subject}}</h5>
            <div style="white-space: pre-line;"
                 class="white--text ph-sensitive">
                {{message.body}}
            </div>
            <div class="mt-4" v-if="message.attachments && message.attachments.length > 0">
                <v-divider></v-divider>
                <message-attachments-component :attachments="message.attachments" light></message-attachments-component>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import MessageStatusComponent from "./MessageStatusComponent";
import api from "../../../api/api";
import MessageAttachmentsComponent from "./attachment/MessageAttachmentsComponent";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
        components: {MessageAttachmentsComponent, MessageStatusComponent, InitialsAvatarComponent},
        data: () => ({
        }),
        props: {
            message: Object,
        },
        methods: {
            emailAdressClick(emailAddress)
            {
                this.$emit("emailAdressClick", emailAddress)
            },
            getImageUrl(item)
            {
                if (item.avatar)
                {
                    return api.BASE_URL + item.avatar
                }
                return ""
            },
        }
    }
</script>


<style scoped>

    .avatar-right {
        position: absolute;
        right: -55px;
        top: 30%;
    }

    .user-card {
        margin: 16px 65px 16px 16px;
    }

</style>
