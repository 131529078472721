import Vue from 'vue'
import Root from './Root.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueTour from 'vue-tour'
import posthogPlugin from "./plugins/posthog"; // import the plugin

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

if (process.env.NODE_ENV === 'production') {
    Vue.use(posthogPlugin)
}

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    store,
    render: h => h(Root)
}).$mount('#app')
