import Axios from 'axios'
import store from "../store";

// if we are in dev mode or not
const BASE_URL = process.env.NODE_ENV === 'production' ? "https://app.uthyrd.se/" : "http://localhost:9000/"
const BASE_URL_FRONTEND = process.env.NODE_ENV === 'production' ? "https://app.uthyrd.se/" : "http://localhost:8080/"
const BASE_DOMAIN = process.env.NODE_ENV === 'production' ? "app.uthyrd.se" : "localhost:9000"

const LOGIN_REDIRECT_URL = "/login"

const API = "api/";

const HEADERS = {
    "Authorization": "Authorization",
    "ContentType": "Content-Type",
    "ApplicationJson": "application/json",
    "Multipart": "multipart/form-data"
};

const ENDPOINTS = {
    APPLICATION: "application",
    AUTH: "auth",
    USERACCOUNT: "useraccount",
    USERACCOUNTS: "useraccounts",
    REGISTRATION: "registration",
    ACCOUNTLOGINS: "accountlogins",
    ACCOUNTLOGIN: "accountlogin",
    EMAIL: "email",
    MESSAGES: "messages",
    MESSAGE: "message",
    RECEIVED: "received",
    CONVERSATIONS: "conversations",
    BOOKINGOBJECTS: "bookingobjects",
    BOOKINGOBJECT: "bookingobject",
    ATTRIBUTES: "attributes",
    ATTRIBUTE: "attribute",
    ATTRIBUTEGROUP: "attributegroup",
    ATTRIBUTEGROUPS: "attributegroups",
    NOTIFICATIONS: "notifications",
    NOTIFICATION: "notification",
    GLOBAL: "global",
    SEARCH: "search",
    FILES: "files",
    FILEDATA: "filedata",
    BOOKINGPERIODS: "bookingperiods",
    BEFORE: "before",
    AFTER: "after",
    STATISTICS: "statistics",
    OCCUPANCY: "occupancy",
    INCOME: "income",
    BOOKINGPERIOD: "bookingperiod",
    BOOKINGPERIODSDISPLAYOPTIONS: "bookingperiodsdisplayoptions",
    BOOKINGPERIODSDISPLAYOPTION: "bookingperiodsdisplayoption",
    CALENDAREVENTS: "calendarevents",
    CALENDAREVENT: "calendarevent",
    CUSTOMERS: "customers",
    CUSTOMER: "customer",
    CALENDARSUBSCRIPTIONTOKENS: "calendarsubscriptiontokens",
    CALENDARSUBSCRIPTIONTOKEN: "calendarsubscriptiontoken",
    PUSHSUBSCRIPTIONS: "pushsubscriptions",
    PUSHSUBSCRIPTION: "pushsubscription",
    NOTIFICATIONSETTINGS: "notificationsettings",
    EXTERNALBOOKERSETTINGS: "externalbookersettings",
    TOURS: "tours",
    WATCHED: "watched",
    TOUR: "tour",
    EVENTLOGS: "eventlogs",
    ENTITIES: "entities",
    PAYMENTLOGS: "paymentlogs",
    PAYMENTREQUESTS: "paymentrequests",
    ASSETS: "assets",
    IMAGES: "images",
    TEXT: "text",
    STATUS: "status",
    UUID: "uuid",
    BOOKING: "booking",
    REQUEST: "request",
    EMAILCONTENT: "emailcontent",
    TYPE: "type",
    DYNAMICDOCUMENTS: "dynamicdocuments",
    DYNAMICDOCUMENT: "dynamicdocument",
    REVISIONS: "revisions",
    PDF: "pdf",
    DYNAMICDATA: "dynamicdata",
    CONTAINERS: "containers",
    ACCOUNTSUBSCRIPTION: "accountsubscription",
    ACCOUNTSUBSCRIPTIONS: "accountsubscriptions",
    STRIPE: "stripe",
    CHECKOUT: "checkout",
    PORTAL: "portal",
    UPCOMING: "upcoming",
    WEBSITES: "websites",
    TEMPLATES: "templates",
    EXTERNAL: "external",
    SECTIONS: "sections",
    TYPES: "types",
    PUBLISHED: "published",
    LOGS: "logs",
    COUNT: "count",
    LISTINGS: "listings",
    ITEM: "item",
    VISITS: "visits",
};

// Performs a GET request to the specified endpoint
async function getFromEndpoint(endpoint, params)
{
    try
    {
        const response = await Axios.get(BASE_URL + API + endpoint,
            {
                withCredentials: true,
                params
            });
        return response.data
    }
    catch (error)
    {
        handleErrors(error);
        return null
    }
}

// Performs a GET request to the specified url
async function getFromUrl(url)
{
    try
    {
        const response = await Axios.get(url,
            {
                withCredentials: true
            });
        return response.data
    }
    catch (error)
    {
        handleErrors(error);
        return null
    }
}

// Performs a POST request to the specified url
async function postToUrl(url, data)
{
    try
    {
        const response = await Axios.post(url, data,
            {
                withCredentials: true
            });
        return response.data
    }
    catch (error)
    {
        handleErrors(error);
        return null
    }
}

// Performs a POST request to the specified endpoint
async function postToEndpoint(endpoint, data)
{
    try
    {
        const response = await Axios.post(BASE_URL + API + endpoint, data,
            {
                withCredentials: true
            });

        return response.data
    }
    catch (error)
    {
        handleErrors(error);
        return null
    }
}

// Same as regular POST but with multipart/form-data as content-type. Normally used to upload files
async function postMultipartToEndpoint(endpoint, file)
{
    try
    {
        let data = new FormData();
        data.append('file', file, file.name);

        const response = await Axios.post(BASE_URL + API + endpoint, data,
            {
                withCredentials: true,
                headers: {
                    [HEADERS.ContentType]: [HEADERS.Multipart]
                },
            });

        return response.data
    }
    catch (error)
    {
        handleErrors(error);
        return null
    }
}

// Performs a PUT request to the specified endpoint
async function putToEndpoint(endpoint, data)
{
    try
    {
        const response = await Axios.put(BASE_URL + API + endpoint, data,
            {
                withCredentials: true
            });
        return response.data
    }
    catch (error)
    {
        handleErrors(error);
        return null
    }
}

// Performs a DELETE request to the specified endpoint
async function deleteReqToEndpoint(endpoint)
{
    try
    {
        const response = await Axios.delete(BASE_URL + API + endpoint,
            {
                withCredentials: true
            });

        return response.data
    }
    catch (error)
    {
        handleErrors(error);
        return null
    }
}

function handleErrors(error)
{
    // Treat network errors without responses as 500s.
    const status = error.response ? error.response.status : 500;
    let errorMsg = error.response.data.msg ? error.response.data.msg : error;
    if (status === 404)
    { // Not found

    }
    else if (status === 401)
    { // Unauthorised
        location.href = LOGIN_REDIRECT_URL
    }
    else
    {
        // Other errors.
        store.commit('setSnackbar', {color: "error", text: errorMsg, timeout: 8000})
    }
    console.error(error)
}

export default {
    getFromEndpoint,
    getFromUrl,
    postToUrl,
    postToEndpoint,
    postMultipartToEndpoint,
    putToEndpoint,
    deleteReqToEndpoint,
    BASE_URL,
    BASE_URL_FRONTEND,
    BASE_DOMAIN,
    API,
    ENDPOINTS,
    HEADERS
}
