<template>
  <v-card
      class="respondent-card"
      max-width="100%"
      min-width="45%"
  >
    <v-card-title class="pb-2">
      <v-avatar
          class="avatar-left">
        <initials-avatar-component
            :imgSrc="getImageUrl(message)"
            :size="42"
            :fullname="message.author"
        ></initials-avatar-component>
      </v-avatar>
      <span class="title">{{ message.author }}</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on" class="subtitle-1 grey--text text--darken-2">{{ $moment(message.createdAt).fromNow() }}</span>
        </template>
        <span>{{ $moment(message.createdAt).format('LLL') }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="message.unread && message.unread > 0">
        <template v-slot:activator="{ on }">
          <v-icon class="ml-2" v-on="on" color="primary">
            mdi-bell-alert-outline
          </v-icon>
        </template>
        <span>Nytt oläst meddelande!</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle class="pt-1" v-if="message.emailAddress">
      <b>Från</b>
      <div>
        <v-chip @click="emailAdressClick(message.emailAddress)"
                color="accent-darken"
                label outlined small>{{ message.emailAddress }}
        </v-chip>
      </div>
    </v-card-subtitle>
    <v-card-text>
      <h5 class="ph-sensitive">{{ message.subject }}</h5>
      <div style="white-space: pre-line;"
           class="ph-sensitive grey--text text--darken-4">
        {{ message.body }}
      </div>
      <div class="mt-4" v-if="message.attachments && message.attachments.length > 0">
        <v-divider></v-divider>
        <message-attachments-component :attachments="message.attachments"></message-attachments-component>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small text @click="showMessageDetails(message)">Visa originalmeddelande</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "../../../api/api";
import MessageAttachmentsComponent from "./attachment/MessageAttachmentsComponent";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
  components: {
    MessageAttachmentsComponent,
    InitialsAvatarComponent
  },
  props: {
    message: Object,
  },
  methods: {
    showMessageDetails(message)
    {
      this.$emit("showMessageDetails", message)
    },
    emailAdressClick(emailAddress)
    {
      this.$emit("emailAdressClick", emailAddress)
    },
    getImageUrl(item)
    {
      if (item.avatar)
      {
        return api.BASE_URL + item.avatar
      }
      return ""
    },
  }
}
</script>


<style scoped>
.avatar-left {
  position: absolute;
  left: -55px;
  top: 30%;
}

.respondent-card {
  margin: 16px 16px 16px 65px;
}
</style>
