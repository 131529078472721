import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/HomeView.vue'
import PageNotFoundComponent from "./components/errors/PageNotFoundComponent";
import ConversationsOverview from "./views/conversations/ConversationsOverview";
import ConversationDetailsView from "./views/conversations/ConversationDetailsView";
import MessagesView from "./views/conversations/MessagesView";
import UserAccountAdminView from "./views/useraccount/UserAccountAdminView";
import BookingObjectsView from "./views/bookingobjects/BookingObjectsView";
import BookingObjectsOverview from "./views/bookingobjects/BookingObjectsOverview";
import BookingObjectDetails from "./views/bookingobjects/BookingObjectDetailsView";
import AdminStandardAttributeView from "./views/systemadmin/attributes/AttributeAdminView";
import BookingPeriodsView from "./views/bookingperiods/BookingPeriodsView";
import BookingPeriodDetailsView from "./views/bookingperiods/BookingPeriodDetailsView";
import CustomersView from "./views/customers/CustomersView";
import CalendarSubscriptionsView from "./views/calendarsubscription/CalendarSubscriptionsView";
import Root from "./Root";
import store from "./store";
import EmailContentView from "./views/emailcontent/EmailContentView";
import DynamicDocumentsView from "./views/dynamicdocuments/DynamicDocumentsView";
import DynamicDocumentsOverview from "./views/dynamicdocuments/DynamicDocumentsOverview";
import DynamicDocumentDetailsView from "./views/dynamicdocuments/DynamicDocumentDetailsView";
import posthog from "posthog-js";

Vue.use(Router)


const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    routes: [
        // --- External & special routes ---
        {
            path: '/login',
            name: 'login',
            props: {layout: "login"},
            component: Root,
        },
        {
            path: '/administration/user/changepassword/:token',
            name: 'changePassword',
            props: {layout: "resetPassword"},
            component: Root,
        },
        {
            path: '/register/email/confirm/:token',
            name: 'confirmEmail',
            props: {layout: "confirmEmail"},
            component: Root,
        },
        {
            path: '/premium/upgrade',
            name: 'upgradeToPremium',
            props: {layout: "upgradeUser"},
            component: Root,
        },
        {
            path: '/external/book',
            name: 'externalBooking',
            props: {layout: "externalBooking"},
            component: Root,
        },
        {
            path: '/external/customer',
            name: 'externalCustomer',
            props: {layout: "externalCustomer"},
            component: Root,
        },
        // --- Application routes ---
        {
            path: '/home',
            name: 'home',
            component: Home
        },
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/bookingperiods',
            name: 'bookingPeriods',
            component: BookingPeriodsView,
        },
        {
            path: '/bookingperiods/details/:id',
            name: 'bookingPeriodDetails',
            component: BookingPeriodDetailsView,
        },
        {
            path: '/bookingperiods/new',
            name: 'newBookingPeriod',
            component: BookingPeriodDetailsView,
            props: {start: String, end: String, bookingObject: Object}
        },
        {
            path: '/customers/',
            name: 'customers',
            component: CustomersView,
            props: {id: Number} // id can be used to pre-select a customer

        },
        {
            path: '/calendarsubscriptions',
            name: 'adminCalendarSubscription',
            component: CalendarSubscriptionsView,
        },
        {
            path: '/messages',
            redirect: {name: 'conversationsOverview'},
            name: 'messages',
            component: MessagesView,
            children: [
                {
                    path: 'overview',
                    name: 'conversationsOverview',
                    component: ConversationsOverview
                }, {
                    path: 'conversations/:id',
                    name: 'conversationDetails',
                    component: ConversationDetailsView
                }
            ]
        },
        {
            path: '/bookingobject',
            redirect: {name: 'bookingObjectsOverview'},
            name: 'bookingObjects',
            component: BookingObjectsView,
            children: [
                {
                    path: 'overview',
                    name: 'bookingObjectsOverview',
                    component: BookingObjectsOverview
                },
                {
                    path: 'edit/:id',
                    name: 'editBookingObject',
                    component: BookingObjectDetails
                },
                {
                    path: 'new',
                    name: 'newBookingObject',
                    component: BookingObjectDetails
                },
            ]
        },
        {
            path: '/dynamicdocuments',
            redirect: {name: 'dynamicDocumentsOverview'},
            name: 'dynamicDocuments',
            component: DynamicDocumentsView,
            children: [
                {
                    path: 'overview',
                    name: 'dynamicDocumentsOverview',
                    component: DynamicDocumentsOverview
                },
                {
                    path: 'edit/:id',
                    name: 'editDynamicDocument',
                    component: DynamicDocumentDetailsView
                },
                {
                    path: 'new',
                    name: 'newDynamicDocument',
                    component: DynamicDocumentDetailsView
                },
            ]
        },
        {
            path: '/administration/user',
            name: 'adminUseraccount',
            component: UserAccountAdminView
        },
        {
            path: '/systemadministration/attribute',
            name: 'adminAttributes',
            component: AdminStandardAttributeView
        },
        {
            path: '/payments',
            name: 'payments',
            component: PageNotFoundComponent
        },
        {
            path: '/administration/email/content',
            name: 'adminEmailContent',
            component: EmailContentView,
        },
        // and finally the default route, when none of the above matches:
        {path: '*', component: PageNotFoundComponent}
    ]
})

const isProd = process.env.NODE_ENV === 'production'

router.beforeEach((to, from, next) => {
    const allowNavigation = !store.state.choosingTourTarget && !store.state.tourActive
    if (isProd && allowNavigation) {
        posthog.capture('$pageleave')
    }
    next(allowNavigation)
})

if (isProd) {
    router.afterEach(() => {
        Vue.nextTick(() => {
            posthog.capture('$pageview')
        })
    })
}


export default router

