<template>
  <div>
    <v-sheet
        height="64"
        tile
    >
      <v-toolbar
          flat
      >
        <v-btn
            class="mr-2"
            color="primary"
            outlined
            small
            @click="setToday"
        >
          Idag
        </v-btn>
        <v-btn
            :disabled="dateIsMinDate"
            class="mr-2"
            color="primary"
            icon
            outlined
            small
            @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="dateIsMaxDate"
            class="ml-2"
            color="primary"
            icon
            outlined
            small
            @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-toolbar>
    </v-sheet>
    <v-sheet max-height="600">
      <v-calendar
          ref="calendar"
          v-model="value"
          :event-color="getEventColor"
          :event-height="35"
          :event-more="false"
          :events="events"
          :locale-first-day-of-year="4"
          :weekdays="weekDays"
          class="booking-calendar"
          color="grey lighten-2"
          locale="sv"
          short-intervals
          short-months
          short-weekdays
          show-week
          type="month"
          @change="change"
          @click:event="selectEvent"
      >
        <template #event="{ event, timed, eventSummary }">
          <v-hover v-slot:default="{ hover }">
            <div
                :class="{'pl-2': true, 'highlighted': hover && event.type === eventTypes.FREE, 'no-click': event.type === eventTypes.OCCUPIED}"
                style="width: 100%; height: 100%; font-size: 15px; line-height: 2.2;">
              {{ eventSummary() }}
              <span v-if="event.type === eventTypes.FREE && event.price && event.price > 0"
                    class="float-right pr-2">
                <v-chip class="pl-2"
                        color="white" label
                        outlined small>
                  <v-icon class="mr-1" x-small>mdi-tag-outline</v-icon>{{ event.priceSeperated }} SEK
                </v-chip></span>
            </div>
          </v-hover>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>

export default {
  components: {},
  data: () => ({
    value: "",
    eventTypes: {
      OCCUPIED: "OCCUPIED",
      FREE: "FREE"
    },
    weekDays: [1, 2, 3, 4, 5, 6, 0]
  }),
  computed: {
    events() {
      let combinedEvents = []
      this.freeEvents.forEach(ev => {
        combinedEvents.push(this.buildCombinedEvent(this.eventTypes.FREE, ev))
      })
      this.occupiedEvents.forEach(ev => {
        combinedEvents.push(this.buildCombinedEvent(this.eventTypes.OCCUPIED, ev))
      })
      return combinedEvents
    },
    dateIsMinDate() {
      // min date is current month
      return this.$moment(this.value).format("YYYY-MM") === this.$moment().format("YYYY-MM")
    },
    dateIsMaxDate() {
      // max date is current month + 3 years
      return this.$moment(this.value).format("YYYY-MM") === this.$moment().add(3, 'years').format("YYYY-MM")
    }
  },
  props: {
    freeEvents: Array,
    occupiedEvents: Array,
  },
  mounted() {
    this.setToday()
  },
  methods: {
    change(date) {
      this.$emit('change', date)
    },
    selectEvent(ev) {
      let event = ev.event
      if (event.type === this.eventTypes.FREE) {
        if (this.$moment(event.start).isSameOrBefore(this.$moment(), 'd')) {
          this.$emit("setSnackbar", "Perioden går ej att välja", "Denna period går inte att välja då startdatumet är för nära inpå eller har redan passerat", "orange darken-3")
        } else {
          this.$emit('select', event)
        }
      }
    },
    setToday() {
      this.value = this.$moment().format("YYYY-MM-DD")
    },
    getEventColor(event) {
      return event.color
    },
    buildCombinedEvent(type, ev) {
      let bookingPeriod = ev.bookingPeriods[0]
      let freeEvent = type === this.eventTypes.FREE
      let eventType = type

      let name = freeEvent ? "Ledigt" : "Upptaget"
      let color = freeEvent ? "#4CAF50" : "#FF5252"
      let periodName = freeEvent ? bookingPeriod.name : null
      let bookingPeriodId = freeEvent ? bookingPeriod.id : null

      let start = this.$moment(ev.start)
      let end = this.$moment(ev.end)
      let now = this.$moment()
      if (start.isSameOrBefore(now, 'd') && end.isSameOrBefore(now, 'd')) {
        name = "Passerat"
        color = "#9d9d9d"
        eventType = this.eventTypes.OCCUPIED
      }

      return {
        name: name,
        start: start.toDate(),
        end: end.toDate(),
        color: color,
        type: eventType,
        price: bookingPeriod.price,
        priceSeperated: bookingPeriod.priceSeperated,
        periodName: periodName,
        bookingPeriodId: bookingPeriodId
      }
    }
  }
}
</script>

<style>
.booking-calendar .v-calendar-weekly__day-label button {
  pointer-events: none;
}

.booking-calendar .v-calendar-weekly__day-label {
  cursor: default;
}

.v-event .highlighted {
  background-color: rgba(255, 255, 255, 0.1);
}

.v-event .no-click {
  pointer-events: none;
}
</style>
