<template>
  <div>
    <v-card :disabled="!bookingPeriod.paymentsInitiatedAt || loading || saving" :loading="loading" flat>
      <v-subheader>
        Betalningsförfrågningar
        <v-spacer></v-spacer>
        <v-switch :label="percentageLabel" v-model="valuesAsPercentage"></v-switch>
      </v-subheader>
      <v-simple-table fixed-header height="230px">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left"></th> <!-- Empty for index -->
            <th class="text-left">Förfallodatum</th>
            <th class="text-left">Status</th>
            <th class="text-left">Status ändrad</th>
            <th class="text-left">Schemalagd</th>
            <th class="text-right">Belopp</th>
            <th class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :class="{'highlight-pulse': isPaymentRequestsEmpty}"
                         @click="newPaymentRequest"
                         color="primary" outlined small
                         v-on="on">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Lägg till en betalningsförfrågan</span>
              </v-tooltip>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="no-hover" v-if="isPaymentRequestsEmpty">
            <td colspan="6">
              <no-data-component class="mt-12 pt-8" icon="mdi-arrow-up" pulse
                                 text="Inga betalningsförfrågningar inlagda. Använd knappen ovan till höger för att lägga till betalningsförfrågningar"></no-data-component>
            </td>
          </tr>
          <tr :key="'paymentrequest_' + index + item.type + item.amount"
              @click="selectPaymentRequest(item, true, index)" class="clickable"
              v-for="(item, index) in paymentRequests">
            <td>{{ index + 1 }}</td>
            <td>{{ item.dueDate }}</td>
            <td>
              <v-chip :color="paymentStatuses[item.status].color" class="white--text" label small>
                {{ item.status | statusString(paymentStatuses, !item.id) }}
              </v-chip>
            </td>
            <td>{{ item.statusUpdatedAt | calendarFormat($moment) }}</td>
            <td>{{ isRequestNotSent(item) && item.scheduledDate ? item.scheduledDate : '' }}</td>
            <td class="text-right">{{
                item.amount |
                    percentageFilter(bookingPeriod.price, valuesAsPercentage)
              }}
              <span>{{ valuesAsPercentage ? '%' : 'SEK' }}</span>
            </td> <!-- TODO - i18n currency -->
            <td></td> <!-- empty for new button -->
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <p class="subtitle-1 mt-3">
        <transition name="slide-x-transition">
          <v-chip :color="sumColor" :input-value="amountMatchesPrice" filter label
                  v-if="!isPaymentRequestsEmpty && !areAllPaymentRequestsCancelled"><b>Totalt:</b> <span
              class="ml-1">{{ totalAmount }} / {{ bookingPeriod.price }}</span>
          </v-chip>
        </transition>
      </p>
      <v-alert
          :value="!isPaymentRequestsEmpty && !amountMatchesPrice && !areAllPaymentRequestsCancelled"
          border="left"
          colored-border
          dense
          elevation="2"
          icon="mdi-arrow-up"
          transition="slide-y-transition"
          type="error">
        <b>OBS!</b> Den totala summan av betalningsförfrågningarna matchar inte priset på perioden
        <v-icon class="float-right" color="error" v-if="$vuetify.breakpoint.mdAndUp">mdi-alert</v-icon>
      </v-alert>
    </v-card>
    <v-dialog max-width="650px" persistent v-model="paymentRequestDialog"> <!-- TODO - refactor to its own component -->
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-credit-card-clock-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-slide-x-transition mode="out-in">
            <v-alert class="mb-0" v-if="invalidPrice && isNewPaymentRequest" prominent type="error">
              Du måste ange ett pris högre än 0 på perioden för att kunna skapa betalningsförfrågningar.
            </v-alert>
            <v-form ref="paymentForm" v-else-if="selectedPaymentRequest" v-model="requestValid">
              <div class="mb-2">
                <v-chip :color="paymentStatuses[selectedPaymentRequest.status].color"
                        class="white--text"
                        label small>{{
                    selectedPaymentRequest.status |
                        statusString(paymentStatuses, !editing)
                  }}
                </v-chip>
              </div>
              <div class="mb-4 text-center" v-if="existingPaymentRequest">
                <v-btn @click="confirmSendPaymentRequest" color="info"
                       v-if="isSendable">
                  Skicka förfrågan
                </v-btn>
                <v-btn @click="confirmRemindPaymentRequest" class="ml-2" color="accent"
                       v-if="isRemindable">Skicka påminnelse
                </v-btn>
                <v-btn @click="confirmPayPaymentRequest" class="ml-2" color="success"
                       v-if="isPayable">
                  Markera som betald
                </v-btn>
              </div>
              <v-subheader class="pl-0 ml-n2">
                <v-icon color="primary">mdi-currency-usd</v-icon>
                Belopp
              </v-subheader>
              <v-text-field
                  :disabled="!valuesAsPercentage"
                  :readonly="!isEditable"
                  :rules="[v => !!v && v > 0 && v <= 100 || 'Du måste ange en procentsats mellan 1-100%']"
                  @change="unsavedChanges = true"
                  autocomplete="off"
                  class="mb-3"
                  hint="Procentuellt belopp av priset för perioden"
                  label="Procentsats"
                  max="100"
                  min="1"
                  persistent-hint
                  required
                  suffix="%"
                  type="number"
                  v-if="valuesAsPercentage"
                  v-model="selectedPaymentRequest.percentage"
              ></v-text-field>
              <v-text-field
                  :disabled="valuesAsPercentage"
                  :readonly="valuesAsPercentage || !isEditable"
                  :rules="[v => !!v && v > 0 || 'Du måste ange ett belopp som över 0']"
                  @change="unsavedChanges = true"
                  autocomplete="off"
                  label="Belopp"
                  min="1"
                  required
                  suffix="SEK"
                  type="number"
                  v-model="selectedPaymentRequest.amount"
              ></v-text-field> <!-- TODO - i18n for currency -->
              <div>
                <v-subheader class="pl-0">
                  <v-icon class="mr-1" color="primary">mdi-calendar-clock</v-icon>
                  Förfallodatum
                </v-subheader>
                <v-menu
                    :close-on-content-click="false"
                    :disabled="!isEditable"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="slide-y-transition"
                    v-model="dueDateMenu"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :rules="dueDateRule"
                        @change="unsavedChanges = true"
                        id="dueDateField"
                        label="Förfallodatum"
                        readonly
                        v-model="selectedPaymentRequest.dueDate"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker :min="$moment().toISOString()" :readonly="!isEditable"
                                 @input="dueDateMenu = false"
                                 v-model="selectedPaymentRequest.dueDate"></v-date-picker>
                </v-menu>
              </div>
              <div>
                <v-card :disabled="!isRequestNotSent(selectedPaymentRequest)" flat>
                  <v-subheader class="pl-0 mt-4">
                    <v-icon class="mr-1" color="primary">mdi-calendar-question</v-icon>
                    Schemaläggning av utskick
                    <v-spacer></v-spacer>
                    <v-switch :disabled="!isRequestNotSent(selectedPaymentRequest)"
                              :input-value="selectedPaymentRequest.scheduledDate"
                              @change="handleScheduledDateSwitch"></v-switch>
                  </v-subheader>
                  <v-card :disabled="!selectedPaymentRequest.scheduledDate" flat>
                    <v-menu
                        :close-on-content-click="false"
                        :disabled="!isEditable"
                        :nudge-right="40"
                        min-width="290px"
                        offset-y
                        transition="slide-y-transition"
                        v-model="scheduledDateMenu"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            :rules="scheduledDateRule"
                            @change="unsavedChanges = true"
                            hint="På detta datum kommer betalningsförfrågan automatiskt skickas till kunden"
                            id="scheduledDateField"
                            label="Schemalagt datum"
                            readonly
                            v-model="selectedPaymentRequest.scheduledDate"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker :min="$moment().add(1, 'd').toISOString()"
                                     :readonly="!isEditable"
                                     @input="scheduledDateMenu = false"
                                     v-model="selectedPaymentRequest.scheduledDate"></v-date-picker>
                    </v-menu>
                  </v-card>
                </v-card>
                <span v-if="!isRequestNotSent(selectedPaymentRequest)">
                                 <v-icon color="primary">mdi-alert-circle-outline</v-icon>
                                Denna förfrågan går inte längre att
                                schemalägga pga dess nuvarande status
                            </span>
              </div>
              <v-alert dense outlined class="mb-0" v-if="invalidPrice && existingPaymentRequest" type="error">
                <b>OBS!</b> Inget pris angivet på perioden!
              </v-alert>
            </v-form>
          </v-slide-x-transition>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :disabled="invalidPrice && isNewPaymentRequest" @click="confirmDeletePaymentRequest" color="error" text v-if="isDeletable">Ta bort</v-btn>
          <v-btn :disabled="invalidPrice && isNewPaymentRequest" @click="confirmCancelPaymentRequest" color="error" text v-else-if="isCancellable">Avbryt
            förfrågan
          </v-btn>
          <v-btn :disabled="invalidPrice && isNewPaymentRequest" @click="confirmRefundPaymentRequest" color="error" text v-else-if="isRefundable">
            Återbetala
          </v-btn>
          <v-btn @click="paymentRequestDialog = false" text v-else-if="!editing">Avbryt</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="invalidPrice && isNewPaymentRequest" @click="paymentRequestDialogSave" color="primary" text>{{ saveButtonText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import paymentPointTypes from "../../../models/paymentPointTypes";
import NoDataComponent from "../../global/NoDataComponent";
import paymentStatuses, {NON_PAYABLE_STATUSES} from "../../../models/paymentStatuses";

export default {
  components: {NoDataComponent},
  data: () => ({
    requestValid: true,
    paymentRequestDialog: false,
    valuesAsPercentage: false,
    selectedPaymentRequest: null,
    paymentPointTypes: paymentPointTypes.dueDateTypesArray,
    paymentPointStrings: paymentPointTypes.typesString,
    paymentStatuses: paymentStatuses.STATUSES,
    editing: false,
    dueDateMenu: false,
    scheduledDateMenu: false,
    loading: false,
  }),
  props: {
    bookingPeriod: Object,
    saving: Boolean,
    unsavedChanged: Boolean,
  },
  filters: {
    typeString(type, paymentPointStrings) {
      return paymentPointStrings[type]
    },
    statusString(status, paymentStatuses, isNew) {
      return isNew ? "Ny" : paymentStatuses[status].text
    },
    calendarFormat(date, moment) {
      return moment(date).calendar()
    },
    percentageFilter(amount, price, valuesAsPercentage) {
      if (valuesAsPercentage) {
        return Math.floor((amount / price) * 100)
      }
      return amount
    }
  },
  watch: {
    selectedPaymentRequest: {
      handler(val) {
        if (this.valuesAsPercentage && val.percentage && parseInt(val.percentage) > 0 && parseInt(val.percentage) <= 100) {
          let amount = Math.floor((parseInt(val.percentage) / 100) * parseInt(this.bookingPeriod.price))
          if (amount > 0) {
            this.selectedPaymentRequest.amount = amount
          }
        }
      },
      deep: true
    }
  },
  computed: {
    saveButtonText() {
      return this.editing ? "Ok" : "Lägg till"
    },
    unsavedChanges: {
      get() {
        return this.unsavedChanged
      },
      set(val) {
        this.$emit('update:unsavedChanged', val)
      }
    },
    sumColor() {
      return this.amountMatchesPrice ? "success" : "error"
    },
    isPaymentRequestsEmpty() {
      return this.paymentRequests == null || this.paymentRequests.length === 0
    },
    dialogTitle() {
      return this.editing ? "Redigerar betalningsförfrågan" : "Ny betalningsförfrågan"
    },
    dueDateRule() {
      return [
        (v => !!v && (!this.isEditable || this.$moment(v).isSameOrAfter(this.$moment(), 'd')) || 'Du måste ange ett förfallodatum som är samma eller efter dagens datum'),
      ]
    },
    scheduledDateRule() {
      return [
        (v => !this.selectedPaymentRequest.scheduledDate || (!!v && (!this.isEditable || this.$moment(v).isAfter(this.$moment(), 'd')) || 'Du måste ange ett datum som är efter dagens datum')),
      ]
    },
    paymentRequests: {
      get() {
        return this.bookingPeriod.paymentRequests
      },
      set(val) {
        this.bookingPeriod.paymentRequests = val
      }
    },
    totalAmount() {
      let total = 0
      this.paymentRequests.forEach((p) => {
        if (p.status !== this.paymentStatuses.CANCELLED.value && p.status !== this.paymentStatuses.REFUNDED.value) {
          total += parseInt(p.amount)
        }
      })
      return total
    },
    amountMatchesPrice() {
      return this.totalAmount === parseInt(this.bookingPeriod.price)
    },
    percentageLabel() {
      return this.$vuetify.breakpoint.xs ? 'Belopp som %' : 'Ange belopp i procent av priset'
    },
    bookingObject() {
      return this.bookingPeriod.bookingObject
    },
    isNewPaymentRequest()
    {
      return (this.selectedPaymentRequest && !this.selectedPaymentRequest.id)
    },
    existingPaymentRequest()
    {
      return (this.selectedPaymentRequest && this.selectedPaymentRequest.id)
    },
    isEditable() {
      return this.isSendable || this.isNewPaymentRequest
    },
    isDeletable() {
      return this.selectedPaymentRequest && this.editing && this.selectedPaymentRequest.status === this.paymentStatuses.NOT_SENT.value
    },
    isCancellable() {
      return this.selectedPaymentRequest && this.editing && (this.selectedPaymentRequest.status === this.paymentStatuses.SENT.value || this.selectedPaymentRequest.status === this.paymentStatuses.REMINDER_SENT.value || this.selectedPaymentRequest.status === this.paymentStatuses.PAYMENT_DUE.value)
    },
    isRefundable() {
      return this.selectedPaymentRequest && this.editing && (this.selectedPaymentRequest.status === this.paymentStatuses.PAID.value || this.selectedPaymentRequest.status === this.paymentStatuses.SHOULD_BE_REFUNDED.value)
    },
    isSendable() {
      return this.selectedPaymentRequest && this.editing && this.selectedPaymentRequest.status === this.paymentStatuses.NOT_SENT.value
    },
    isRemindable() {
      return this.selectedPaymentRequest && this.editing && (this.selectedPaymentRequest.status === this.paymentStatuses.SENT.value || this.selectedPaymentRequest.status === this.paymentStatuses.PAYMENT_DUE.value)
    },
    isPayable() {
      return this.selectedPaymentRequest && this.editing &&
          !NON_PAYABLE_STATUSES.includes(this.selectedPaymentRequest.status);
    },
    invalidPrice() {
      return Number.parseInt(this.bookingPeriod.price) <= 0
    },
    areAllPaymentRequestsCancelled() {
      return this.bookingPeriod.paymentRequests.every((pr) => pr.status === paymentStatuses.STATUSES.CANCELLED.value)
    },
  },
  methods: {
    selectPaymentRequest(item, edit, index) {
      item.index = index
      if (edit) {
        this.$set(item, 'percentage', Math.floor((item.amount / this.bookingPeriod.price) * 100))
      }
      this.selectedPaymentRequest = item
      this.editing = edit
      this.paymentRequestDialog = true
    },
    newPaymentRequest() {
      this.selectPaymentRequest(Object.assign({}, {
        amount: "1",
        percentage: "1",
        status: this.paymentStatuses.NOT_SENT.value,
        statusUpdatedAt: this.$moment().format('YYYY-MM-DD HH:mm'),
        dueDate: this.$moment().add(1, 'w').format('YYYY-MM-DD'),
        scheduledDate: null
      }), false)
      this.paymentRequestDialog = true
    },
    paymentRequestDialogSave() {
      let form = this.$refs.paymentForm
      if (form.validate()) {
        if (!this.editing) {
          this.paymentRequests.push(this.selectedPaymentRequest)
        }
        this.paymentRequestDialog = false
        this.unsavedChanges = true
      }
    },
    confirmDeletePaymentRequest() {
      let index = this.selectedPaymentRequest.index
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: `Är du säker på att du vill ta bort denna betalningsförfrågan?`,
        actionClick: () => {
          this.deletePaymentRequest(index)
        }
      })
    },
    deletePaymentRequest(index) {
      this.paymentRequestDialog = false
      this.paymentRequests.splice(index, 1)
      this.unsavedChanges = true
    },
    confirmCancelPaymentRequest() {
      let id = this.selectedPaymentRequest.id
      let status = this.paymentStatuses.CANCELLED
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Stäng",
        actionBtnText: "Avbryt förfrågan",
        title: "Bekräfta att du vill avbryta",
        text: `Är du säker på att du vill avbryta denna betalningsförfrågan?`,
        actionClick: () => {
          this.updatePaymentRequestStatus(id, status)
        }
      })
    },
    confirmRefundPaymentRequest() {
      let id = this.selectedPaymentRequest.id
      let status = this.paymentStatuses.REFUNDED
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Återbetala",
        title: "Bekräfta återbetalning",
        text: `Är du säker på att du vill återbetala denna betalningsförfrågan?`,
        actionClick: () => {
          this.updatePaymentRequestStatus(id, status)
        }
      })
    },
    confirmSendPaymentRequest() {
      let id = this.selectedPaymentRequest.id
      let status = this.paymentStatuses.SENT
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Skicka förfrågan",
        title: "Bekräfta utskick",
        text: `Är du säker på att du vill skicka denna betalningsförfrågan till huvudkunden (${this.getMainCustomer().email})?`,
        actionClick: () => {
          this.updatePaymentRequestStatus(id, status)
        }
      })
    },
    confirmRemindPaymentRequest() {
      let id = this.selectedPaymentRequest.id
      let status = this.paymentStatuses.REMINDER_SENT
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Skicka påminnelse",
        title: "Bekräfta utskick av påminnelse",
        text: `Är du säker på att du vill skicka en påminnelse för denna betalningsförfrågan till huvudkunden (${this.getMainCustomer().email})?`,
        actionClick: () => {
          this.updatePaymentRequestStatus(id, status)
        }
      })
    },
    getMainCustomer() {
      return this.bookingPeriod.customers.find((customer) => customer.mainContact)
    },
    confirmPayPaymentRequest() {
      let id = this.selectedPaymentRequest.id
      let status = this.paymentStatuses.PAID
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Markera som betald",
        title: "Bekräfta registrering av betalning",
        text: `Är du säker på att du vill markera denna betalningsförfrågan som betald?`,
        actionClick: () => {
          this.updatePaymentRequestStatus(id, status)
        }
      })
    },
    updatePaymentRequestStatus(id, newStatus) {
      this.$emit("updatePaymentRequestStatus", id, newStatus)
      this.paymentRequestDialog = false
    },
    handleScheduledDateSwitch(val) {
      if (val && this.paymentRequestDialog) {
        this.selectedPaymentRequest.scheduledDate = this.$moment().add(1, 'd').format("YYYY-MM-DD")
      } else {
        this.selectedPaymentRequest.scheduledDate = null
      }
    },
    isRequestNotSent(paymentRequest) {
      return paymentRequest && paymentRequest.status === paymentStatuses.STATUSES.NOT_SENT.value
    }
  }
}
</script>

<style scoped>
tr.no-hover {
  background: none !important;
}
</style>
